import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";
import * as Sentry from "@sentry/sveltekit";
import { initializeClerkClient } from "clerk-sveltekit/client";
import {
  PUBLIC_APP_VERSION,
  PUBLIC_CLERK_PUBLISHABLE_KEY,
  PUBLIC_SENTRY_DSN,
} from "$env/static/public";
import { dev } from "$app/environment";

if (!dev) {
  Sentry.init({
    dsn: PUBLIC_SENTRY_DSN,
    environment: import.meta.env.MODE,
    release: PUBLIC_APP_VERSION,

    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [replayIntegration()],
  });
}

initializeClerkClient(PUBLIC_CLERK_PUBLISHABLE_KEY, {
  afterSignUpUrl: "/",
  afterSignInUrl: "/",
  signInUrl: "/login",
  signUpUrl: "/signup",
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
