import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [0];

export const dictionary = {
		"/(pages)": [~8,[3]],
		"/(pages)/account": [17,[3]],
		"/(admin)/admin": [~4,[2]],
		"/(admin)/admin/imageChooser": [~5,[2]],
		"/(pages)/bookmarks": [~18,[3]],
		"/(pages)/(recipe)/c/[authorSlug]": [~9,[3]],
		"/(pages)/(recipe)/c/[authorSlug]/[recipeSlug]": [~10,[3]],
		"/(pages)/(seo)/d/creators": [~12,[3]],
		"/(pages)/(seo)/d/creators/[slug]": [~13,[3]],
		"/(pages)/(seo)/d/recipes": [~14,[3]],
		"/(pages)/(seo)/d/tags": [~15,[3]],
		"/(pages)/(seo)/d/tags/[slug]": [~16,[3]],
		"/(admin)/login": [6,[2]],
		"/(pages)/recipes": [~19,[3]],
		"/(pages)/recipes/search": [~20,[3]],
		"/(admin)/signup": [7,[2]],
		"/(pages)/submit-request": [~21,[3]],
		"/(pages)/(recipe)/tag/[slug]": [~11,[3]],
		"/[...path]": [~22]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';